import React from 'react';
import classNames from 'classnames/bind';
import { conditionClassModifier } from '@surfline/web-common';
import { Divider, Typography } from '@mui/material';
import styles from './RatingsCell.module.scss';

const cx = classNames.bind(styles);
const getConditionsClassNameBg = (className: string, conditions: string, loading: boolean) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-background`]: !loading,
  });

const getConditionsClassNameColor = (className: string, conditions: string, loading: boolean) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-color`]: !loading,
  });

type Props = {
  text?: boolean;
  rating: string | undefined;
  loading: boolean;
};

const RatingsCell: React.FC<Props> = ({ rating, loading, text = false }) =>
  text ? (
    <div
      data-testid="forecast-table-ratings-text-cell"
      className={cx([
        styles.textWrapper,
        getConditionsClassNameColor('text', rating || '', loading),
      ])}
    >
      <Typography variant="overline2" className={styles.ratingText}>
        {loading || !rating ? '--' : rating?.replace(/_/g, ' ')}
      </Typography>
    </div>
  ) : (
    <div data-testid="forecast-table-ratings-cell" className={styles.wrapper}>
      {rating ? (
        <div
          data-testid="forecast-table-ratings-bar"
          className={getConditionsClassNameBg('bar', rating, loading)}
        />
      ) : (
        <Divider
          data-testid="forecast-table-ratings-empty"
          orientation="vertical"
          className={styles.emptyRatingsBar}
        />
      )}
    </div>
  );

export default RatingsCell;
